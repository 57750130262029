<style>
@import url("../assets/styles/bootstrap.css");
@import url("../assets/styles/style.css");
@import url("../assets/styles/style.custom.css");
@import url("../assets/styles/highlights/highlight_blue.css");
@import url("../assets/fonts/css/fontawesome-all.min.css");
</style>

<template>
  <div id="page">
    <div id="footer-bar" class="footer-bar-1">
      <router-link to="/about"><i class="fa fa-info-circle"></i><span>{{ $t('footer.about') }}</span></router-link>
      <router-link to="/home"><i class="fa fa-home"></i><span>{{ $t('footer.home') }}</span></router-link>
      <a href="#" v-on:click="openSettings()"><i class="fa fa-cog"></i><span>{{ $t('footer.settings') }}</span></a>
    </div>

    <router-view />

    <!--  Menus -->
    <menu-settings />
    <div class="menu-hider" v-on:click="close()"></div>
  </div>
</template>

<script>
import menuSettings from "@/components/menus/menu.settings.vue";
import { menus } from "@/app/app.themes.js";

export default {
  name: "App",
  components: { menuSettings  },
  methods: {
    openSettings() {
      menus.open("menu-settings");
    },
    close() {
      menus.close();
    },
  },
};
</script>

