<template>
  <div>
    <div id="menu-settings" class="menu menu-box-bottom menu-box-detached">
      <div class="menu-title mt-0 pt-0">
        <h1>{{ $t('footer.settings') }}</h1>
        <p class="color-highlight">{{ $t('settings.title-sub') }}</p><a href="#" class="close-menu" v-on:click="close()"><i class="fa fa-times"></i></a>
      </div>
      <div class="divider divider-margins mb-n2"></div>
      <div class="content">
        <div class="list-group list-custom-small">
          <a data-trigger-switch="switch-dark-mode" class="pb-2 ms-n1">
            <i class="fa font-12 fa-moon rounded-s bg-highlight color-white me-3"></i>
            <span>{{ $t('settings.dark-mode') }}</span>
            <div class="custom-control scale-switch ios-switch">
              <input type="checkbox" class="ios-input" id="switch-dark-mode" v-bind:checked="isDarkMode" v-on:click="darkModeSwitch()">
              <label class="custom-control-label" for="switch-dark-mode"></label>
            </div>
            <i class="fa fa-angle-left"></i>
          </a>
        </div>
        <div class="list-group list-custom-large">
          <a href="#" v-on:click="openMenuLanguage()" class="border-0">
            <i class="fa font-14 fa-globe-europe bg-highlight rounded-s"></i>
            <span>{{ $t('settings.language') }}</span>
            <strong>{{ $t('settings.language-sub') }}</strong>

            <i class="fa fa-angle-right"></i>
          </a>
        </div>
        <div class="list-group list-custom-large">
          <a href="#" class="border-0">
            <i> <img class=" mb-3" v-bind:src="require('@/assets/images/icon-152x152.png')" alt="LinfoNeo" width="32"></i>
            <span>LinfoNeo App</span>
            <strong>vers. {{ appVersion }}</strong>
            <a href="#" v-if="isToBeInstalled" v-on:click="promptInstall()"><span class="badge bg-blue-dark">INSTALL APP</span></a>
            <i class="fa fa-check"></i>
          </a>
        </div>
      </div>
    </div>

    <menu-language />

    <div id="menu-pwa-updates" class="menu menu-box-bottom menu-box-detached rounded-m" data-menu-effect="menu-over">
      <div class="text-center top-30">
        <h1 class="mt-4 font-700 font-22">Update available!</h1>
        <p class="boxed-text-xl">New content is available; please refresh your application in order to update with latest version.</p>
        <div class="row">
          <div class="col-12">
            <a href="#" v-on:click="updateApp()" class="ms-3 me-3 btn btn-sm rounded-sm btn-full text-uppercase font-900 bg-green-dark">UPDATE</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import menuLanguage from "@/components/menus/menu.language.vue";

import { menus } from "@/app/app.themes.js";
import { darkMode } from "@/app/app.themes.js";
import pwaUpdate from "@/app/app.mixins.pwaUpdate.js";

export default {
  name: "Settings",
  components: { menuLanguage },
  mixins: [pwaUpdate],
  mounted() {
    this.$root.$on("pwa::updateAvailable", function () {
      menus.open("menu-pwa-updates");
    });
  },
  computed: {
    isDarkMode() {
      return darkMode.isDarkMode;
    },
    appVersion() {
      return process.env.VUE_APP_VERSION;
    },
  },
  methods: {
    openMenuLanguage: function () {
      menus.open("menu-language");
    },
    close: function () {
      menus.close();
    },
    darkModeSwitch: function () {
      darkMode.switch();
    },
    updateApp() {
      this.refreshApp();
    },
  },
};
</script>
