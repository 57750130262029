// https://dev.to/drbragg/handling-service-worker-updates-in-your-vue-pwa-1pip
// https://web.dev/customize-install/
export default {
    data() {
        return {
            // refresh variables
            refreshing: false,
            registration: null,
            updateExists: false,

            deferredPrompt: null
        }
    },
    computed: {
        isUpdateAvailable() {
            return this.updateExists;
        },
        isToBeInstalled() {
            return this.deferredPrompt !== null;
        }
    },
    created() {
        // Listen for our custom event from the SW registration
        document.addEventListener('serviceWorkerPWAUpdateEvent', this.updateAvailable, { once: true });

        // Prevent multiple refreshes
        navigator.serviceWorker.addEventListener('controllerchange', () => {
            if (this.refreshing) return;
            this.refreshing = true;
            // Here the actual reload of the page occurs
            window.location.reload();
        })

        // Initialize deferredPrompt for use later to show browser install prompt.

        window.addEventListener('beforeinstallprompt', (e) => {
            // Prevent the mini-infobar from appearing on mobile
            e.preventDefault();
            // Stash the event so it can be triggered later.
            this.deferredPrompt = e;
            // Optionally, send analytics event that PWA install promo was shown.
            console.log(`'beforeinstallprompt' event was fired.`);
        });
    },

    methods: {
        // Store the SW registration so we can send it a message
        // We use `updateExists` to control whatever alert, toast, dialog, etc we want to use
        // To alert the user there is an update they need to refresh for
        updateAvailable(event) {
            this.registration = event.detail
            this.updateExists = true
            this.$root.$emit("pwa::updateAvailable");

        },

        // Called when the user accepts the update
        refreshApp() {
            this.updateExists = false
            // Make sure we only send a 'skip waiting' message if the SW is waiting
            if (!this.registration || !this.registration.waiting) return
            // send message to SW to skip the waiting and activate the new SW
            this.registration.waiting.postMessage({ type: 'SKIP_WAITING' })
        },


        promptInstall() {
            if (!this.deferredPrompt) return;
            // Show the install prompt
            this.deferredPrompt.prompt();
            // Wait for the user to respond to the prompt:
            this.deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === "accepted") {
                    console.log("User accepted the install prompt");
                } else {
                    console.log("User dismissed the install prompt");
                }

                this.deferredPrompt = null;
            });
        }


    },
}