import Vue from 'vue'
import { router } from '@/app/app.router.js'
import { store } from '@/app/app.store.js'
import '@/app/app.registerServiceWorker.js'
import { i18n } from '@/app/app.i18n.js'
import { VBToggle } from "bootstrap-vue";
import App from '@/app/App.vue'
import appInsights from '@/app/app.appinsights.js'

// applicationinsights-web
if (appInsights) {
  appInsights.loadAppInsights()

  Vue.config.errorHandler = err => {
    appInsights.trackException({ exception: err })
    console.error(err)
  }
}

Vue.directive('b-toggle', VBToggle);

new Vue({
  store,
  router,
  i18n,
  config: {
    productionTip: false
  },
  render: h => h(App),
}).$mount('#page')