<template>
  <div id="menu-language" class="menu menu-box-bottom menu-box-detached rounded-m" data-menu-effect="menu-over">
    <div class="menu-title mt-n1">
      <h1>{{ $t('settings.language') }}</h1>
      <p class="color-theme opacity-50">{{ $t('settings.language-sub') }}</p>
      <a href="#" class="close-menu" v-on:click="close()"><i class="fa fa-times"></i></a>
    </div>
    <div class="content mb-0 mt-0">
      <div class="divider mt-2 mb-0"></div>
      <div class="list-group list-custom-small ps-1 check-visited">
        <a href="#" v-for="(lang, index) in supportedLanguages" :key="lang.language" v-on:click="changeLanguage(lang.language)" v-bind:class="{ 'border-0': index === (supportedLanguages.length - 1), 'visited-link': isCurrentLanguage(lang.language)}">
          <img class="me-3 mt-n1" width="20" v-bind:src="require('@/assets/images/flags/' + lang.flag +'.png')"><span>{{lang.title}}</span><i class="fa"></i></a>
      </div>
      <a href="#" v-on:click="gotoBack()" class="mb-3 btn btn-full btn-m rounded-sm bg-highlight shadow-xl text-uppercase font-900 mt-4">{{ $t('settings.back-to') }}</a>

    </div>
  </div>
</template>

<script>
import { menus } from "@/app/app.themes.js";
import { locales } from "@/app/app.i18n.js";

export default {
  data: function () {
    return {
      currentLanguage: locales.currentLanguage,
    };
  },
  computed: {
    supportedLanguages() {
      return locales.supportedLanguages;
    },
  },
  methods: {
    gotoBack: function () {
      menus.open("menu-settings");
    },
    close: function () {
      menus.close();
    },
    isCurrentLanguage(lang) {
      return lang === this.currentLanguage;
    },
    changeLanguage(lang) {
      if (lang !== this.currentLanguage) {
        locales.changeLanguage(lang);
        this.currentLanguage = lang;
        this.$i18n.locale = lang;
      }
    },
  },
};
</script>
