import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const Home = () => import( /* webpackChunkName: "home" */ '@/views/Home.vue');
const About = () => import( /* webpackChunkName: "about" */ '@/views/About.vue');

export const router = new VueRouter({
  mode: "hash",
  linkActiveClass: "active-nav",
  routes: [
    {
      path: '/home', component: Home,
      beforeEnter: ((from, to, next) => {
        let welcomed = (localStorage.getItem('welcomed') || 'false') === 'true';
        if (!welcomed) {
          localStorage.setItem('welcomed', 'true');
          next('/about');
        } else next();
      })
    },
    { path: '/about', component: About },
    { path: '*', redirect: '/home' } // otherwise redirect to home
  ]
});
