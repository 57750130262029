import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export const locales = {
  get supportedLanguages() {
    return [
      { flag: 'United-States', language: 'en', title: 'English' },
      { flag: 'Italy', language: 'it', title: 'Italiano' }
    ]
  },
  get currentLanguage() {
    return localStorage.getItem('language') || 'en';
  },
  get loadLocaleMessages() {
    const messages = {}
    locales.supportedLanguages.forEach(lang => {
      messages[lang.language] = require('@/assets/locales/' + lang.language + '.json');
    })
    return messages
  },
  changeLanguage(lang) {
    if (lang) localStorage.setItem('language', lang);
  },
}


export const i18n = new VueI18n({
  locale: locales.currentLanguage,
  fallbackLocale: 'en',
  messages: locales.loadLocaleMessages
})
