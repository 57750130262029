//Caching Global Variables
var i, e, el;
if (!i || !e || !el) {
    //nothing
}
export const themes = {
    init_template() {
        //Activating Menus
        menus.init();
        //Activating Dark Mode
        darkMode.init();

        //Don't jump on Empty Links
        const emptyHref = document.querySelectorAll('a[href="#"]')
        emptyHref.forEach(el => el.addEventListener('click', e => {
            e.preventDefault();
            return false;
        }));
    }
}

export const menus = {
    init() {
        document.querySelectorAll('.menu').forEach(el => { el.style.display = 'block' })
        //Setting  Widths
        var menuSheets = document.querySelectorAll('.menu-box-bottom, .menu-box-top, .menu-box-modal');
        menuSheets.forEach(function (e) {
            if (e.getAttribute('data-menu-width') === "cover") {
                e.style.width = '100%'
                e.style.height = '100%'
            } else {
                e.style.width = (e.getAttribute('data-menu-width')) + 'px'
                e.style.height = (e.getAttribute('data-menu-height')) + 'px'
            }
        })
    },
    open(menuData) {
        var wrappers = document.querySelectorAll('.header, #footer-bar, .page-content');
        //Close Existing Opened Menus 
        const activeMenu = document.querySelectorAll('.menu-active');
        for (let i = 0; i < activeMenu.length; i++) { activeMenu[i].classList.remove('menu-active'); }
        //Open Clicked Menu
        //var menuData = el.getAttribute('data-menu');


        //document.getElementById(menuData).style.display = 'block';
        document.getElementById(menuData).classList.add('menu-active');
        document.getElementsByClassName('menu-hider')[0].classList.add('menu-active');
        //Check and Apply Effects
        var menu = document.getElementById(menuData);
        var menuEffect = menu.getAttribute('data-menu-effect');
        var menuLeft = menu.classList.contains('menu-box-left');
        var menuRight = menu.classList.contains('menu-box-right');
        var menuTop = menu.classList.contains('menu-box-top');
        var menuBottom = menu.classList.contains('menu-box-bottom');
        var menuWidth = menu.offsetWidth;
        var menuHeight = menu.offsetHeight;

        if (menuEffect === "menu-push") {
            menuWidth = document.getElementById(menuData).getAttribute('data-menu-width');
            if (menuLeft) { for (let i = 0; i < wrappers.length; i++) { wrappers[i].style.transform = "translateX(" + menuWidth + "px)" } }
            if (menuRight) { for (let i = 0; i < wrappers.length; i++) { wrappers[i].style.transform = "translateX(-" + menuWidth + "px)" } }
            if (menuBottom) { for (let i = 0; i < wrappers.length; i++) { wrappers[i].style.transform = "translateY(-" + menuHeight + "px)" } }
            if (menuTop) { for (let i = 0; i < wrappers.length; i++) { wrappers[i].style.transform = "translateY(" + menuHeight + "px)" } }
        }
        if (menuEffect === "menu-parallax") {
            menuWidth = document.getElementById(menuData).getAttribute('data-menu-width');
            if (menuLeft) { for (let i = 0; i < wrappers.length; i++) { wrappers[i].style.transform = "translateX(" + menuWidth / 10 + "px)" } }
            if (menuRight) { for (let i = 0; i < wrappers.length; i++) { wrappers[i].style.transform = "translateX(-" + menuWidth / 10 + "px)" } }
            if (menuBottom) { for (let i = 0; i < wrappers.length; i++) { wrappers[i].style.transform = "translateY(-" + menuHeight / 5 + "px)" } }
            if (menuTop) { for (let i = 0; i < wrappers.length; i++) { wrappers[i].style.transform = "translateY(" + menuHeight / 5 + "px)" } }
        }
    },

    close() {
        var wrappers = document.querySelectorAll('.header, #footer-bar, .page-content');
        const activeMenu = document.querySelectorAll('.menu-active');
        for (let i = 0; i < activeMenu.length; i++) { activeMenu[i].classList.remove('menu-active'); }
        for (let i = 0; i < wrappers.length; i++) { wrappers[i].style.transform = "translateX(-" + 0 + "px)" }
    }
}

export const darkMode = {
    get isDarkMode() {
        return (localStorage.getItem('theme-mode') === "dark-mode");
    },
    init() {
        document.body.className = (this.isDarkMode ? 'theme-dark' : 'theme-light');
    },
    switch() {
        if (document.body.className == "theme-light") { darkMode.removeTransitions(); darkMode.activateDarkMode(); }
        else if (document.body.className == "theme-dark") { darkMode.removeTransitions(); darkMode.activateLightMode(); }
        setTimeout(function () { darkMode.addTransitions(); }, 350);
    },
    activateDarkMode() {
        document.body.classList.add('theme-dark');
        document.body.classList.remove('theme-light', 'detect-theme');
        localStorage.setItem('theme-mode', 'dark-mode');
    },
    activateLightMode() {
        document.body.classList.add('theme-light');
        document.body.classList.remove('theme-dark', 'detect-theme');
        localStorage.setItem('theme-mode', 'light-mode');
    },
    removeTransitions() { var falseTransitions = document.querySelectorAll('.btn, .header, #footer-bar, .menu-box, .menu-active'); for (let i = 0; i < falseTransitions.length; i++) { falseTransitions[i].style.transition = "all 0s ease"; } },
    addTransitions() { var trueTransitions = document.querySelectorAll('.btn, .header, #footer-bar, .menu-box, .menu-active'); for (let i = 0; i < trueTransitions.length; i++) { trueTransitions[i].style.transition = ""; } },
}

export const cards = {
    init() {
        //Card Extender
        //const cards = document.getElementsByClassName('card');
        const cards = document.querySelectorAll('.card, .card-extender');
        
        function card_extender() {
            var headerHeight, footerHeight, headerOnPage, windowHeight, footerOnPage;
            headerOnPage = document.querySelectorAll('.header:not(.header-transparent)')[0];
            footerOnPage = document.querySelectorAll('#footer-bar')[0];

            headerOnPage ? headerHeight = document.querySelectorAll('.header')[0].offsetHeight : headerHeight = 0
            footerOnPage ? footerHeight = document.querySelectorAll('#footer-bar')[0].offsetHeight : footerHeight = 0

            for (let i = 0; i < cards.length; i++) {
                if (cards[i].getAttribute('data-card-height') === "cover") {
                    if (window.matchMedia('(display-mode: fullscreen)').matches) {  windowHeight = window.outerHeight; }
                    if (!window.matchMedia('(display-mode: fullscreen)').matches) {  windowHeight = window.innerHeight; }
                    var coverHeight = windowHeight - headerHeight - footerHeight + 'px';
                }
                if (cards[i].hasAttribute('data-card-height')) {
                    var getHeight = cards[i].getAttribute('data-card-height');
                    cards[i].style.height = getHeight + 'px';
                    if (getHeight === "cover") {
                        //var totalHeight = getHeight
                        cards[i].style.height = coverHeight
                    }
                }
            }
        }

        if (cards.length) {
            card_extender();
            window.addEventListener("resize", card_extender);
        }
    }
}